import React, { useEffect } from 'react'
import background from '../images/background.png'
import { useNavigate } from 'react-router-dom'
import { server_API } from '../config';
import { useState } from 'react';
const Pricingtabs = () => {
    const navigate = useNavigate()
    const routeToPricing = (planid, planPrice, planName)=>{
        navigate('/checkout',{state:{planid:planid, planPrice:planPrice, planName:planName}})
    }
    const [planKeys, setPlanKeys] = useState({})
    useEffect(()=>{
        if(server_API){
            if(server_API.includes('test')){
                setPlanKeys(testPlans)
            }
            else{
                setPlanKeys(prodPlans)
            }
        }
    },[server_API])
    const testPlans = {
        "kickoff":"plan_PfgixyVbWLLwlg",
        "startup":"plan_PfgjsC1Q73fk1x",
        "premium":"plan_PfgkLiB1GKMR2N"
    }
    const prodPlans = {
        "kickoff":"plan_PfpnO6Yy87iUGT",
        "startup":"plan_Pfpnl8UomLLSzi",
        "premium":"plan_PfpoRQner0s3Lu"
    }
  return (
    <div>
        <div  className="md:grid md:grid-cols-3 w-full space-y-4 md:space-y-0 md:space-x-3 mt-16">
            <button onClick={()=>{routeToPricing(planKeys.kickoff,12500, "kickoff")}} style={{backgroundImage:`url(${background})`}} className="bg-local w-full cursor-pointer hover:scale-110 transition-all bg-right-top  bg-cover py-6 px-4 border rounded-lg text-center text-white border-[#30374F]">
                <p className="font-thicboi font-semibold text-3xl  ">Kickoff Plan</p>
                <p className="mt-5 font-thicboi text-3xl">₹ 12500</p>
                <p className="">50 interviews</p>
                <p className="mt-4">Monthly</p>
            </button>
            <button onClick={()=>{routeToPricing(planKeys.startup,23500, "startup")}}  style={{backgroundImage:`url(${background})`}} className="bg-local w-full cursor-pointer hover:scale-110 transition-all bg-right-top  bg-cover py-6 px-4 border rounded-lg text-center text-white border-[#30374F]">
                <p className="font-thicboi font-semibold text-3xl  ">Startup Plan</p>
                <p className="mt-5 font-thicboi text-3xl">₹ 23500</p>
                <p className="">100 interviews</p>
                <p className="mt-4">Monthly</p>
            </button>
            <button onClick={()=>{routeToPricing(planKeys.premium, 63000, "premium")}}  className=" py-6 px-4 border-2 hover:scale-110 cursor-pointer w-full transition-all rounded-lg text-center bg-white text-[#30374F] border-[#30374F]">
                <p className="font-thicboi font-semibold text-3xl  ">Premium Plan</p>
                <p className="mt-5 font-thicboi text-3xl">₹ 63000</p>
                <p className="">300 interviews</p>
                <p className="mt-4">Monthly</p>
            </button>
        </div>
    </div>
  )
}

export default Pricingtabs