var server_API = "https://test.northstarz.ai"
var local_API  = "localhost:3000"
var server_django_api = "https://test.northstarz.ai/django"
var hosted_api = "https://northstarz.ai"
var django_hosted_api = "https://interviewguru.co/django"

if(!(window.location.href.includes('localhost') || window.location.href.includes('122.176.112.216') || window.location.href.includes('test.interviewguru.co') || window.location.href.includes('test.northstarz.ai'))){
    server_API = hosted_api
    server_django_api = django_hosted_api
}


const data1 = {
    "API":"interviewvision.com",
}

export {server_API, local_API, server_django_api, data1} 
