import React from 'react'
import { useState, useEffect } from 'react'
import background from '../images/background.png'
import logo from '../images/logo.png'
import WhiteButton from '../components/WhiteButton'
import TransparentButton from '../components/TransparentButton'
import HeroSectionInnerMostCard from '../components/HeroSectionInnerMostCard'
import HeroSectionTopMostCard from '../components/HeroSectionTopMostCard'
import table1 from '../images/table1.png'
import table2 from '../images/table2.png'
import HumanEyeResumeEvaluation from '../components/HumanEyeResumeEvaluation'
import viewprofile from '../images/viewprofile.png'
import vc from '../images/vc.png'
import InfoSection from '../components/InfoSection'
import BubbleSection from '../components/BubbleSection'
import Advantages from '../components/Advantages'
import Numbers from '../components/Numbers'
import Process from '../components/Process'
import CaseStudies from '../components/CaseStudies'
import Features from '../components/Features'
import Love from '../components/Love'
import Integrate from '../components/Integrate'
import Pricing from '../components/Pricing'
import Footer from '../components/footer'
import { Link } from 'react-router-dom'
import FAQ from '../components/Faq'
import second_bg from '../images/Testimonials.png'
import Carousel from '../components/Carousel'
import nasscom from '../images/nasscom.png'
import Header from '../components/Header'
import AboutusInnerMostCard from '../components/AboutusInnerMostCard'
import invertedbackground from '../images/secondBackground.png'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import NewCaseStudies from '../components/NewCaseStudies'
AOS.init(
    {
        delay:500
    }
);

//Make 
//Logos
//Logo sizing in carousel
//Interview works same dabba size
// Candidate section goes below
//Remove made in love in india
const Home = () => {

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    const [email, setEmail] = useState()
  return (
    <div>
   <div
    style={{ backgroundImage: `url(${background})` }}
    className="bg-local min-h-screen bg-cover py-5 md:py-8 px-10 md:px-20  font-thicboi relative overflow-hidden"
>
 
    <div className="hidden md:flex  absolute inset-0 opacity-20 pointer-events-none z-0">
        <div
            style={{
                backgroundImage:
                    'linear-gradient(to right, rgba(128,128,128,0.3) 2px, transparent 2px), linear-gradient(to bottom, rgba(128,128,128,0.3) 2px, transparent 2px)',
                backgroundSize: '100px 100px',
                border: '1px solid rgba(128,128,128,0.2)',
            }}
            className="w-full h-full"
        ></div>
    </div>

   
    {/* <div className="hidden md:flex absolute inset-0 z-0 pointer-events-none">
        <div
            style={{
                background: 'radial-gradient(circle, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%)',
            }}
            className="w-full h-full"
        ></div>
    </div> */}

    <Header />
    <div data-aos="fade-up" className="my-20 text-center relative z-10 flex flex-col gap-1">
        <p className="my-3 text-[#D4C4FF] font-dmSerif italic text-6xl md:text-7xl">
            Discover Intelligence
        </p>
        <p className="font-thicboi text-2xl md:text-4xl text-white">
            Identify Star Talent in 15 minutes
        </p>
        <div className="w-full flex items-center justify-center">
            <p className="text-white text-center font-extralight max-w-xl">
            Transform your hiring process by making data-driven decisions that pinpoint candidates who display innovation, adaptability, collaboration, and technical excellence to build your A-team with precision and confidence.            </p>
            {/* Make this in single line */}
        </div>
    </div>

    <div data-aos="fade-up" className='flex justify-center mb-20 md:mb-10'>
    <div className='bg-[#0E101B] p-2 flex items-center gap-10 border-[1px] rounded-md border-[#30374F] mb-10'>
    <div>
  <input 
    placeholder='Enter your email address' 
    className='font-thicboi bg-[#0E101B] p-2 w-full text-base  text-[#7D89B0] placeholder-gray-500 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
    value={email}
    onChange={(e)=>{setEmail(e.target.value)}}
  />
</div>

<div className='text-base md:text-xl'>
<WhiteButton to={'demo'} email={email} text={"Request a demo"}/>
</div>
    </div>
    </div>

    <div
        style={{ zIndex: 2 }}
        className=" relative md:hidden flex justify-center items-center w-full"
    >
        <HeroSectionInnerMostCard />
    </div>

    <div data-aos="fade-up" className="text-center mt-20 font-thicboi space-x-2 text-white justify-center items-center md:hidden flex relative z-10">
        <p>Incubated by: </p>
        <img src={nasscom} className="h-7" alt="Nasscom Logo" />
    </div>
</div>



        <div className="bg-darkBlue md:py-0 py-10">
                <div className="-translate-y-32 hidden   md:flex justify-center  ">
                    <HeroSectionInnerMostCard/>
                    
                </div>
            
            <div>
                <p className="text-[#9B8AFB] text-center font-thicboi font-semibold text-xs md:text-sm">BUILD YOUR A-TEAM!</p>
                <p className="text-center font-semibold text-white font-thicboi text-2xl md:text-4xl py-5 pb-10 flex flex-col"><span>Identify Star Performers for your</span><span>organisation</span></p>
            </div>
            <div className="md:mx-40 mx-4 md:h-[60rem] items-stretch flex flex-col">
                <div className="md:flex items-stretch space-y-4 md:space-y-0  w-full md:space-x-4 md:h-[56rem]">
                    <div className="flex flex-col gap-6 md:w-1/3 h-full">
                        <div className="relative h-full w-full">
                            <div
                                className=" flex flex-col gap-3 font-thicboi customBorder2 w-full border h-full  rounded-lg bg-darkBlue  overflow-clip "
                            >
                            <p className="text-white opacity-45 text-xl px-8 py-3 mt-10">Candidate Dashboard</p>
                            <div className="flex ">
                                <img src={table2} className="h-60 pl-12"></img>
                            </div>
                            <img src={table1} className="h-44 pr-12 -mt-24"></img>
                            
                            <div className="p-6 flex flex-col gap-2">
                                <p className="textgradient font-thicboi font-semibold  text-left text-3xl">Discover Star Talent</p>
                                <p className="font-thicboi text-base text-[#B9C0D4] mt-4">Assess Innovation, Adaptability and Collaboration scores. Select candidates who bring in all-round efficiency to drive organisational growth 
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className="relative ">
                            <div className="p-6 flex flex-col gap-4  font-thicboi customBorder2 w-full  rounded-lg bg-darkBlue">
                                <p className="textgradient font-thicboi font-semibold  text-left text-3xl">Your recruiter's superpower</p>
                                <p className="font-thicboi text-base text-[#B9C0D4] mt-4">Helps recruiters screen candidates 3X more candidates with high accuracy. Evaluate candidates for any technical role - Software Development, Sales,  Digital Marketing, Customer Support or Operations. Shortlist the top candidates for functional rounds 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col md:w-1/3 h-full customBorder2">
                        <div className="relative w-full h-full ">
                            <div
                                className="flex flex-col gap-7 relative font-thicboi rounded-lg bg-darkBlue  overflow-clip "
                            >
                            <div className="p-6 flex flex-col gap-4">
                                <p className="textgradient font-thicboi font-bold mt-10 text-left text-3xl">Screen resumes with Human Eye Efficiency</p>
                                <p className="font-thicboi text-base text-[#B9C0D4] ">Assess resumes as per the prescribed job description and understand exactly what applicants are trying to articulate as well as a human recruiter but in seconds. 
                                </p>
                            </div>
                            <div className="p-3 ">
                                <div></div>
                                <HumanEyeResumeEvaluation/>
                            </div>
                            <div className="p-6 flex flex-col gap-4 mb-8">
                                <p className="textgradient font-thicboi font-semibold  text-left text-3xl">AI interview analysis</p>
                                <p className="font-thicboi text-base text-[#B9C0D4] mt-4">Our AI & ML driven system evaluates multiple candidates in real-time on employability skills and technical skills with high accuracy
                                </p>
                            </div>
                            </div>
                        </div>
                        
                    </div>

                    <div className="flex flex-col gap-6 md:w-1/3 h-full items-stretch customBorder2">
                        <div
                            className="relative font-thicboi  w-full h-2/3 rounded-lg bg-darkBlue  overflow-clip "
                        >
                        <p className="text-white opacity-45 text-xl px-6 py-3 mt-10">Consolidated Applicant View</p>
                        <div className="p-4">
                            <img src={viewprofile} className="h-52 "></img>
                        </div>
                        <div className="p-6 flex flex-col gap-2">
                            <p className="textgradient font-thicboi font-semibold  text-left text-3xl">Talent That Powers Progress
                            </p>
                            <p className="font-thicboi text-base text-[#B9C0D4] mt-4">Select candidates who bring in all round efficiency to drive organisational growth</p>
                        </div>
                        </div>
                        <div className="relative  font-thicboi  w-full h-2/3  rounded-lg bg-darkBlue flex flex-col ">
                            <img src={vc} className=" object-cover rounded-lg "></img>
                            <div className="p-6 flex flex-col gap-2">
                                <p className="textgradient font-thicboi font-semibold  text-left text-3xl">Hire with confidence</p>
                                <p className="font-thicboi text-base text-[#B9C0D4] mt-4">Select candidates objectively, make data-driven decisions, with minimum bias.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="md:mx-40 mx-4 mt-20  md:py-10">
                <InfoSection />
            </div>
            {/* <div className="md:mx-40 mx-4 py-20">
                <BubbleSection></BubbleSection>
            </div> */}
            <div className="bg-[#00000033] px-4 md:px-40 py-10 md:py-20">
                <Advantages/>
            </div>
            <div
            style={{backgroundImage:`url(${background})`}}
            className="bg-local bg-top   bg-cover font-thicboi py-10 md:py-[96px] px-4 md:px-40"
            >
                <Numbers/>
            </div>
            <div
            className="bg-black font-thicboi py-10 md:py-[96px] overflow-clip px-4 md:px-40 relative"
            >
                <Process></Process>
            </div>
           
            <div className="bg-[#00000033] md:px-40 px-4 py-10 md:py-[96px] font-thicboi">
                <NewCaseStudies/>
            </div>
            <div
        style={{backgroundImage:`url(${invertedbackground})`}}
        className="bg-local w-full  bg-cover font-thicboi flex gap-10 md:pl-40 py-10 md:py-20 px-6 md:pr-20"
        >
           <div data-aos="fade-up" className='w-full flex flex-col items-center md:items-start gap-10 z-40 scale-90'>
                <div className='font-thicboi  items-center md:items-start gap-3'>
                <span className='text-white font-bold md:text-5xl text-3xl mb-4  leading-20'>
                Hire Smarter. Hire Faster. <br></br>Hire Fairer.
                </span>
                <p className='text-white text-center md:text-start text-lg'>
                Revolutionize Your Recruitment with AI-Driven Solutions
                </p>
                </div>
                <div data-aos="fade-up"  className="flex items-center space-x-4">
                <WhiteButton text={"Request a demo"} to={"/demo"} />
                                    {/* <TransparentButton text={"Contact Sales"}/> */}
                </div>
           </div>
           <div className='w-full hidden md:flex scale-75'>
           
           <div className="w-full -translate-y-10 ">
                <AboutusInnerMostCard/>
                
            </div>
           
      
           </div>
        </div>
            <div  className="bg-[#0c121d] md:px-40 px-4 py-10 md:py-20 font-thicboi">
                <Features/>
            </div>

            <div className="bg-[#00000033] py-10 md:py-[80px] px-4 md:px-40 font-thicboi">
                <Love></Love>
            </div>
            <div 
            style={{backgroundImage:`url(${second_bg})`}}
            className="py-10 md:py-[96px] bg-local bg-bottom bg-cover">
                <Carousel/>
            </div>
            <div className="bg-[#0c121d] md:px-40 px-4 py-10 md:py-20 font-thicboi">
                <Pricing></Pricing>
            </div>
            <div className="bg-[#00000033] hidden md:block py-10 md:py-[80px] px-40 font-thicboi">
                <Integrate/>
            </div>
            <div className=''>
                <FAQ fade={true}/>
            </div>
        </div>
        <Footer/>

    </div>
  )
}

export default Home