import React, {useState, useEffect} from 'react'
import logo from '../images/logo.png'
import WhiteButton from '../components/WhiteButton'
import TransparentButton from '../components/TransparentButton'
import background from '../images/Privacybackground.png'
import Footer from '../components/footer'
import FAQ from "../components/Faq"
import { Link , useLocation } from 'react-router-dom'
import Header from '../components/Header'
import axios from 'axios'
const Demo = () => {
  const location = useLocation()
  const [name, setName] = useState('')
  const [company, setCompany] = useState('')
  const [workphone, setWorkPhone] = useState('')
  const [code, setCode] = useState('')
  const [addInfo, setAddInfo] = useState('')
  const [email, setEmail] = useState(location.state?.email || '')

  useEffect(()=>{
    window.scrollTo(0, 0);
},[])

  const sendEmail = ()=>{
    axios.post(`https://northstarz.ai/v1/company/earlyaccess`,{
      "name":name,
      "email":email,
      "phone":code+workphone,
      "org":company,
      "message":addInfo
  })
  .then((response)=>{
      console.log(response)
      alert('Thank you, we will get in touch with you soon!')
  })
  .catch((error)=>{
      console.log(error)
      alert('Something went wrong, please try again later')
  })
  }

  return (
    <div className='h-full bg-[#0E101B]'>
        <div
       style={{
        backgroundImage: `url(${background})`,
        
      }}
        className="  h-full bg-cover  font-thicboi px-6 md:px-20"
        >
           <Header/>
            
            <div className='flex flex-col gap-10 items-center w-full pb-20 md:pb-40'>
            <div className="flex flex-col py-20 items-center text-center w-full">
               <div className='md:w-[50%]'>
               <p className=" text-white font-dmSerif italic text-7xl">Book a Demo</p>
               </div>
                <div className="md:w-[70%] flex items-center justify-center ">
                    <p className="text-white text-center font-extralight font-sans">
                    Schedule a 15-min demo with our experts
                    </p>
                </div>
               
            </div>
           
            
            </div>
            
            
        </div>
       
        <div className='bg-[#0E101B] px-6 md:px-20 skew-y-6 py-20 flex w-full -mt-20 overflow-x-clip justify-center flex-col  gap-10'>
        <div className='flex justify-center w-full -skew-y-6'>

<div className='flex flex-col gap-16 px-6 md:px-20 py-12 customBorder2  rounded-md font-thicboi backdrop-filter backdrop-blur-xl aspect-auto -translate-y-32 justify-center' >
    <div className='flex flex-col gap-8'>
    <div className='text-white flex flex-col md:flex-row items-center justify-center w-full gap-4'>
       <div className='flex flex-col gap-2 w-full'>
         <span className='font-thicboi font-bold text-xs'>
            FULL NAME *
         </span>
         <div className='border-[1px] border-[#7D89B0] rounded'>
         <input 
placeholder='Ex Ved pathak' 
className='font-thicboi bg-transparent p-2 w-full text-base  text-[#FFFFFF] placeholder-gray-500 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={name} 
onChange={(e)=>{setName(e.target.value)}}
/>
         </div>
       </div>
       <div className='flex flex-col gap-2 w-full'>
       <span className='font-thicboi font-bold text-xs'>
WORK EMAIL *
</span>
<div className='border-[1px] border-[#7D89B0] rounded'> 
<input 
placeholder='Ex vedpathak@gmail.com' 
className='font-thicboi bg-transparent p-2 w-full text-base  text-[#FFFFFF] placeholder-gray-500 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={email}
onChange={(e)=>{setEmail(e.target.value)}}
/>
</div>
       </div>
     </div>
    
        
     <div className='text-white flex flex-col md:flex-row items-center justify-center w-full gap-4'>
       <div className='flex flex-col gap-2 w-full'>
         <span className='font-thicboi font-bold text-xs'>
            COMPANY *
         </span>
         <div className='border-[1px] border-[#7D89B0] rounded'>
         <input 
placeholder='Ex Google' 
className='font-thicboi bg-transparent p-2 w-full text-base  text-[#FFFFFF] placeholder-gray-500 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={company}
onChange={(e)=>{setCompany(e.target.value)}}
/>
         </div>
       </div>
       <div className='flex flex-col gap-2 w-full'>
       <span className='font-thicboi font-bold text-xs'>
WORK PHONE *
</span>
<div className='border-[1px] border-[#7D89B0] rounded flex'> 
<div className='flex justify-center items-center p-2 border-r-[1px] border-[#7D89B0] w-[45%]'> 
<select value={code}
onChange={(e)=>{setCode(e.target.value)}}
defaultValue="+91" className=" bg-transparent  p-1 w-full text-white focus:outline-none focus:border-none">
<option value="+1" className=''>+1 </option>
<option value="+44">+44 </option>
<option value="+61">+61 </option>
<option value="+91">+91</option>
<option value="+81">+81</option>
<option value="+49">+49</option>

</select>

</div>
<input 
placeholder='Ex 8989898989' 
className='font-thicboi bg-transparent p-2 w-full text-base  text-[#FFFFFF] placeholder-gray-500 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={workphone}
onChange={(e)=>{setWorkPhone(e.target.value)}}
/>
</div>
       </div>
     </div>
      

      <div  className='text-white flex items-center justify-center w-full gap-4'>
      <div className='flex flex-col gap-2 w-full'>
         <span className='font-thicboi font-bold text-xs'>
            ADDITIONAL INFORMATION
         </span>
         <div className='border-[1px] border-[#7D89B0] rounded'>
         <textarea 
placeholder='Ex Write anyiting' 
className='font-thicboi bg-transparent p-2 w-full text-base  text-[#FFFFFF] placeholder-gray-500 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={addInfo}
onChange={(e)=>{setAddInfo(e.target.value)}}
/>
         </div>
       </div>
      </div>
    </div>
    <div className='flex items-center justify-center'>
    <div className="flex items-center space-x-4">
      <WhiteButton onclick={sendEmail}  text={"Request a demo"}/>
    </div>
    </div>
    
     
       
     
     
    </div>

    
    </div>
    <div className="text-white -skew-y-6 ">
      <p className="text-center font-thicboi text-3xl font-semibold">Contact Details</p>
      <p className="text-center text-xl mt-5"><span >Address</span></p>
      <p className="text-white text-center">Awfis Mantri Commerce <br></br>
      5th Floor, Commerce Mantri, 12, 1 & 2,<br></br> Bannerghatta Rd, BTM 2nd Stage, BTM Layout,<br></br> Bengaluru, Karnataka 560076</p>
      <p className="text-center text-xl mt-5"><span >Phone Number</span></p>
      <p className="text-center">+91 88262 50111</p>
      <p className="text-center text-xl mt-5"><span >Email</span></p>
      <p className="text-center">info@northstarz.ai</p>

    </div>

    <div className='flex flex-col justify-center -skew-y-6 w-full gap-16'>
    <FAQ/>

    </div>

       
        </div>
       

       <Footer/>
        
    </div>
  )
}

export default Demo