import React from 'react'
import PricingMonthlyTable from './PricingMonthlyTable'
import Pricingtabs from './Pricingtabs'

const Pricing = () => {
  return (
    <div>
            <p className="text-[#9B8AFB] text-center font-thicboi font-semibold text-sm">PRICING</p>
            <p className="text-white text-5xl text-center  my-5">How much does it cost</p>
            <p className="text-[#B9C0D4] text-center">From Application to Interview Completion – Effortless and Efficient</p>
            {/* <div className="my-16 inline-block relative left-1/2 -translate-x-1/2">
                <div className="border border-[#FFFFFF33] bg-[#FFFFFF1A] rounded-full items-center space-x-3 flex p-2">
                    <div className="bg-white py-3 px-6 rounded-full">
                        <p className="">Monthly Billed</p>
                    </div>
                    <div className="py-3 px-6">
                        <p className="text-white ">Annually Billed</p>
                    </div>
                </div>
            </div> */}
            {/* <PricingMonthlyTable></PricingMonthlyTable> */}
            <Pricingtabs></Pricingtabs>
            <p className="text-center text-white mt-16 font-thicboi">If you have requirements for a larger volume, please write to us on <a href="mailto:info@northstarz.ai" className="underline">info@northstarz.ai</a> and we will reach out to you</p>
    </div>
  )
}

export default Pricing