import React, { useEffect, useState } from 'react'
import Footer from '../components/footer'
import Header from '../components/Header'
import second_bg from '../images/Testimonials.png'
import logo from '../images/logo.png';
import CreatableSelect from "react-select/creatable";
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import { server_API } from '../config';
import axios from 'axios';
import { useLocation } from 'react-router-dom';


const Company = () => {
    const [email, setEmail] = useState('')
    const location_ref = useLocation()
    const { error, isLoading, Razorpay } = useRazorpay();
    const [code, setCode] = useState(["", "", "", ""]);
    const [checked,setChecked] = useState(false);
    const [tab,setTab] =  useState("trial")
    const [currentStep, setCurrentStep] = useState(0);
    const [companyName, setCompanyName] = useState('')
    const [industry, setIndustry] = useState('')
    const [file, setFile] = useState(null);
    const [website, setWebsite] = useState('')
    const [companyEmail, setCompanyEmail] = useState('')
    const [companyPhone,setCompanyPhone] = useState('')
    const [companyAdd1,setCompanyAdd1] = useState('')
    const [companyAdd2,setCompanyAdd2] = useState('')
    const [companyPincode,setCompanyPincode] = useState('')
    const [companyCity,setCompanyCity] = useState('')
    const [companyCountry,setCompanyCountry] = useState('')
    const [companyState,setCompanyState] = useState('')
    const [location, setLocation] = useState("");
    const [language,setLanguage] = useState("");
    const [departments,setDepartments] = useState("");
    const [locationValues, setLocationValues] = useState([]);
    const [departmentsValues, setDepartmentsValues] = useState([]);
    const [languageValues, setLanguagesValues] = useState([]);
    const [Employees,setEmployees] = useState('');
    const [extra,setExtra] = useState('');
    const [cid, setCid] = useState('')
    const {planid, planPrice, planName} =location_ref.state

    const handlePayment = (sub_id) => {
      const options = {
        key: "rzp_live_r5QV0B2p18jMyi",
        subscription_id: sub_id,
        name: "Novelio Technoliges Pvt Ltd",
        // description: "Test Transaction",
        // order_id: "order_PVWc7GfAqQbvuI", // Generate order_id on server
        // customer_id:"cust_PVWc7LEnjMBXQl",
        recurring:"1",
        prefill:{
          "email":email
        },
        handler: (response) => {
          axios.post(`${server_API}/v1/payment/create`,{
            paymentid:response.razorpay_payment_id,
            emailid:email
          })
          .then((res) => {
            console.log(res)
            setTab("company")
          })
          .catch((err) => {
            console.log(err)
          })
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
        },
        
        theme: {
          color: "#F37254",
        },
      };
  
      const razorpayInstance = new Razorpay(options);
      razorpayInstance.open();
    };


    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
    
        if (droppedFile && (droppedFile.type === "image/jpeg" || droppedFile.type === "image/png")) {
          setFile(droppedFile);
        } else {
          alert("Please drop a JPEG or PNG image.");
        }
      };
    
      const handleFileSelect = (e) => {
        const selectedFile = e.target.files[0];
    
        if (selectedFile && (selectedFile.type === "image/jpeg" || selectedFile.type === "image/png")) {
          setFile(selectedFile);
        } else {
          alert("Please select a JPEG or PNG image.");
        }
      };
      const handleDeleteFile = () => {
        setFile(null); 
      };

    
      const handleDragOver = (e) => {
        e.preventDefault(); // To allow drop
      };
    const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];
    const stepPositions = [2, 27, 52, 77];
  
    const handleStepClick = (index) => {
      if(index ==1){
        axios.post(`${server_API}/v1/company/create`,{
          name:companyName,
        // dbname:companyName.replace(" ","_")
        })
        .then((response)=>{
          const formData = new FormData
          formData.append('file',file)
          formData.append('companyid',response.data.message)
          localStorage.setItem('companyId', response.data.message)
          setCid(response.data.message)
          axios.post(`${server_API}/v1/company/logo`, formData)
          .then((response)=>{
            console.log(response)
          })
          .catch((error)=>{
            console.log(error)
          })
          console.log(response)
          axios.post(`${server_API}/v1/user/create`,{
            comapnyid:response.data.message,
            "privilege": "Admin",
            email: email,
            name: "User",
            status:"sent",
            password:"password"
          })
          .then((res)=>{
            console.log(res)
          })
          .catch((err)=>{
            console.log(err)
          })
          axios.post(`${server_API}/v1/payment/companycreated`,{
            emailid:email,
            companycreated:true,
            companyid:response.data.message
          })
          .then((res)=>{
            
            console.log(res)
          })
          .catch((error)=>{
            console.log(error)
          })
          axios.post(`${server_API}/v1/invoice/create`,{
            "companyid" : cid,
            "plan_price" : 30,
            "plan_name" : planName,
            "email" : email
          })
          .then((res)=>{
            console.log(res)
          })
          .catch((error)=>{
            console.log(error)
          })
        })
        .catch((err)=>{
          console.log(err)
        })
        
      }

      if(index == 3){
        axios.patch(`${server_API}/v1/company/update`,{
          uuid:cid,
          website:website,
          email:companyEmail,
          phone:companyPhone,
          address:companyAdd1 + ' ' + companyAdd2 + ' ' + companyCity + ' ' + companyState + ' ' + companyCountry +' '+ companyPincode,
          location:locationValues.toString(),
          departments:departmentsValues
        })
        .then((response) =>{
          console.log(response);
        })
        .catch((error) =>{
          console.log(error);
        })
      }
      setCurrentStep(index); 
    };

    const handleEmployees = (index) => {
      setEmployees(index); 
    };


    const handleInputChange = (value, index) => {
      if (!/^\d?$/.test(value)) return;
  
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
  
    
      if (value && index < 3) {
        document.getElementById(`code-input-${index + 1}`).focus();
      }
    };
  
    const handleKeyDown = (e, index) => {
      if (e.key === "Backspace") {
        const newCode = [...code];
        if (!newCode[index] && index > 0) {
          document.getElementById(`code-input-${index - 1}`).focus();
        }
        newCode[index] = ""; 
        setCode(newCode);
      }
    };
    

    const handleTrial = ()=>{
      axios.post(`${server_API}/v1/payment/subscription`,
      {
        emailid:email,
        planid:planid
      }
    )
    .then((response)=>{
      console.log(response);
      handlePayment(response.data.message)  
    })
    .catch((err)=>{
      console.log(err)
      alert('Email id is already in use')
    })
      
    }

    const handleDone = ()=>{
      localStorage.setItem("tab","trial")
      setTab("trial");
    }

     const majorCities = [
    { label: "Delhi", value: "Delhi" },
    { label: "Mumbai", value: "Mumbai" },
    { label: "Kolkata", value: "Kolkata" },
    { label: "Chennai", value: "Chennai" },
    { label: "Jaipur", value: "Jaipur" },
    { label: "Pune", value: "Pune" },
    { label: "Ahmedabad", value: "Ahmedabad" },
    { label: "Bangalore", value: "Bangalore" },
    { label: "Hyderabad", value: "Hyderabad" },
    { label: "Surat", value: "Surat" },
    { label: "Agra", value: "Agra" },
    { label: "Kanpur", value: "Kanpur" },
    { label: "Lucknow", value: "Lucknow" },
    { label: "Nagpur", value: "Nagpur" },
    { label: "Indore", value: "Indore" },
    { label: "Bhopal", value: "Bhopal" },
    { label: "Chandigarh", value: "Chandigarh" },
    { label: "Patna", value: "Patna" },
    { label: "Dehradun", value: "Dehradun" },
    { label: "Coimbatore", value: "Coimbatore" },
    { label: "Madurai", value: "Madurai" },
    { label: "Vijayawada", value: "Vijayawada" },
    { label: "Mangalore", value: "Mangalore" },
    { label: "Mysore", value: "Mysore" },
    { label: "Trivandrum", value: "Trivandrum" },
    { label: "Tirupati", value: "Tirupati" },
    { label: "Nellore", value: "Nellore" },
    { label: "Guntur", value: "Guntur" },
    { label: "Warangal", value: "Warangal" },
    { label: "Kurnool", value: "Kurnool" },
    { label: "Rajahmundry", value: "Rajahmundry" },
    { label: "Bhubaneswar", value: "Bhubaneswar" },
    { label: "Guwahati", value: "Guwahati" },
    { label: "Ranchi", value: "Ranchi" },
    { label: "Jamshedpur", value: "Jamshedpur" },
    { label: "Cuttack", value: "Cuttack" },
    { label: "Durgapur", value: "Durgapur" },
    { label: "Asansol", value: "Asansol" },
    { label: "Siliguri", value: "Siliguri" },
    { label: "Dhanbad", value: "Dhanbad" },
    { label: "Rourkela", value: "Rourkela" },
    { label: "Haldia", value: "Haldia" },
    { label: "Agartala", value: "Agartala" },
    { label: "Shillong", value: "Shillong" },
    { label: "Imphal", value: "Imphal" },
    { label: "Kohima", value: "Kohima" },
    { label: "Aizawl", value: "Aizawl" },
    { label: "Gangtok", value: "Gangtok" },
    { label: "Itanagar", value: "Itanagar" },
    { label: "Dimapur", value: "Dimapur" },
    { label: "Silchar", value: "Silchar" },
    { label: "Dibrugarh", value: "Dibrugarh" },
    { label: "Tripura", value: "Tripura" },
    { label: "Jorhat", value: "Jorhat" },
    { label: "Gurugram", value: "Gurugram" },
  ];

  const departmentsOptions = [
    { label: "Sales", value: "Sales" },
    { label: "Marketing", value: "Marketing" },
    { label: "Finance", value: "Finance" },
    { label: "Information Technology", value: "Information Technology" },
    { label: "IT Infrastructure", value: "IT Infrastructure" },
    { label: "Human Resources", value: "Human Resources" },
    { label: "Data Science", value: "Data Science" },
    { label: "Collections", value: "Collections" },
    { label: "Community Management", value: "Community Management" }
  ];
  const languageOptions = [
    { label: "option1", value: "option1" },
    { label: "option2", value: "option2" },
    { label: "option3", value: "option3" },
  ];

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  useEffect(() => {
    if (location.length > 0) {
      const lv = location.map((l) => {
        return l.value;
      });
      setLocationValues(lv);
    }

    if (departments.length > 0) {
      const lv = departments.map((l) => {
        return l.value;
      });
      setDepartmentsValues(lv);
    }
    if (language.length > 0) {
      const lv = language.map((l) => {
        return l.value;
      });
      setLanguagesValues(lv);
    }
  }, [location,language,departments]);


  return (
    <div className='h-full '>
        <div
        className=" bg-[#0d121e] h-full bg-cover font-thicboi px-6 md:px-20 py-10"
        >
          <Header/>
            {/* <div className="flex flex-col  items-center  text-center gap-16 px-6 md:p-20">
               
                <div className="w-full flex items-center justify-center ">
                    <p className="text-white text-center font-bold font-thicboi text-3xl md:text-5xl">Self Sign up</p>
                </div> 

            </div> */}

            
            
        </div>

        <div
  style={{ backgroundImage: `url(${second_bg})` }}
  className="bg-local bg-bottom bg-cover md:flex gap-16 h-full">
  
  {/* Left Content */}
  <div className="py-40 px-5 md:px-28 md:py-[96px] flex flex-col gap-14">
    <div>
      <img src={logo} className="h-14" alt="logo" />
    </div>
    
   {tab ==  "trial"&&(<div className="flex flex-col gap-6">
      <p className="font-thicboi font-semibold text-3xl text-left text-white">
        Sign up and experience Northstarz for 30 Days – Absolutely Free!
      </p>
      <p className="font-thicboi font-medium text-base text-left text-white">
        Discover how our AI-powered platform can transform your recruitment process.
      </p>
      <ul className="list-disc px-4">
        <li className="font-thicboi font-normal text-base text-left text-[#84818A]">
          Streamline hiring
        </li>
        <li className="font-thicboi font-normal text-base text-left text-[#84818A]">
          Find top talent with precision
        </li>
        <li className="font-thicboi font-normal text-base text-left text-[#84818A]">
          Save time and resources
        </li>
      </ul>
      <p className="font-thicboi font-normal text-base text-left text-[#84818A]">
        Sign up now and take the first step toward smarter hiring!
      </p>
    </div>)}

    {tab ==  "company"&&(<div className="flex flex-col  gap-6">
      <p className="font-thicboi font-semibold text-3xl text-left text-white">
      Your payment was successful, and we’re thrilled to have you on board.
      </p>
  
      <p className="font-thicboi font-normal text-base text-left text-[#84818A]">
      Thank you for joining Northstarz! We're excited to help you transform your recruitment process. 

      </p>
      <p className="font-thicboi font-normal text-base text-left text-[#84818A]">
    

To get started, please provide a few final details. Once completed, you'll unlock the full potential of our platform to streamline and enhance your hiring journey.
      </p>
    </div>)}
  </div>
  
  {/* White Background Section */}
  <div className="bg-white w-full h-full rounded-l-xl flex-auto">
  <div className="py-40 px-5 md:px-28 md:py-[96px] flex flex-col gap-14">
   {tab == "trial"&& (<div className=' flex flex-col gap-10'>
    <div className='font-medium text-3xl'>
    Sign up for a 30-day Free Trial
    </div>
    <div className="flex flex-col gap-11">
    <div className='flex flex-col gap-2 border-b-[1px] border-gray-500 '>
        <span className='font-thicboi text-base text-[#84818A] '>Email address</span>
    <input 

className='font-thicboi font-normal bg-transparent  w-full text-base  text-black placeholder-gray-400 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={email} 
onChange={(e)=>{setEmail(e.target.value)}}
/>
    </div>
    {/* <div className="flex flex-col gap-2">
      <span className="font-thicboi text-base text-[#84818A]">Verification Code</span>
      <div className="flex gap-4">
        {code.map((digit, index) => (
          <input
            key={index}
            id={`code-input-${index}`}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleInputChange(e.target.value, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            className="w-10 h-12 text-center bg-gray-300 text-xl border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        ))}
      </div>
    </div> */}
    <div className="flex items-center gap-4">
    <input
    type="checkbox"
    className="w-10 h-10 text-blue-500 border-gray-300 rounded "
    value={checked}
    onChange={()=>{
        setChecked(!checked)
    }}
  />
      <p className='text-[#84818A] font-thicboi text-sm font-normal'>By clicking Create account, I agree that I have read and accepted the Terms of Use and Privacy Policy.</p>
    </div>

    <div className="flex flex-col gap-2">
      <button className={checked && email.length>0?'bg-[#2E90FA] text-white font-thicboi text-sm font-normal rounded-xl p-3':"bg-gray-400 text-white font-thicboi text-sm font-normal rounded-xl p-3"} disabled={!(checked && email.length>0)} onClick={handleTrial}>Start a free trial</button>
    </div>

    
<hr></hr>
      <p className="font-thicboi font-normal text-base  text-[#84818A] mb-4">
        Already have an account? <span className='text-[#2E90FA] underline'>Sign in</span>
      </p>
    </div>
    </div>)}
    {tab == "company"&& (<div className=' flex flex-col gap-10'>

        <div className="flex flex-col items-center">

      <div className="relative w-full max-w-xl">

        <div className="flex items-center w-full absolute top-1/2 transform -translate-y-1/2 ">
          {steps.map((step, index) => (
            <div key={index} className="flex items-center w-full relative">

              <div
                className={`flex items-center justify-center w-10 h-10 rounded-full  transition-all duration-300 cursor-pointer ${
                "  border-4 border-[#305591] text-white"

                }`}
                onClick={() => handleStepClick(index)}
              >
        
              </div>
            
              {index !== steps.length - 1 && (
                <div
                  className={`flex-grow h-1 bg-gray-300 transition-all duration-300`}
                ></div>
              )}
            </div>
          ))}
        </div>

 
        <div
          className={`absolute top-1/2 transform -translate-y-1/2 transition-all duration-500 ease-in-out bg-[#305591] rounded-full w-6 h-6`}
          style={{
            left: `${stepPositions[currentStep]}%`
            , 
          }}
        ></div>
      </div>

    </div>
   {currentStep == 0&& (<>
    <div className='flex flex-col gap-2' >

   <span className='font-medium text-3xl'>Tell us about your company </span> 
   <p className='font-thicboi font-normal text-base text-[#92929D]'>To set up your account, we just need a few more details</p>

    </div>
    <div className="flex flex-col gap-14">
        <div className='px-2 flex flex-col gap-8'> 
            <div className='flex flex-col gap-2 border-b-[1px] border-gray-500 '>
        <span className='font-thicboi text-base text-[#84818A] '>Company Name<span className='font-thicboi text-base text-red-600 '>*</span></span>
        
    <input 

className='font-thicboi font-normal bg-transparent  w-full text-base  text-black placeholder-gray-400 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={companyName} 
onChange={(e)=>{setCompanyName(e.target.value)}}
/>
    </div>
    <div className="flex flex-col gap-2">
    <span className='font-thicboi text-base text-[#84818A] '>Logo<span className='font-thicboi text-base text-red-600 '>*</span></span>
                    <div
                      className="flex flex-col gap-1 items-center justify-center border-[1px] border-[#DDDFE1] rounded-md p-6"
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                    >
                      {file && (
                         <div className="flex items-start gap-4">
                         
                         <img
                           src={URL.createObjectURL(file)}
                           alt="Uploaded file preview"
                           className="w-32 h-32 object-cover mt-2"
                         />
                         <button
                           className=" text-red-500"
                           onClick={handleDeleteFile}
                         >
                           &#10005; {/* This is the cross (X) icon */}
                         </button>
                       </div>
                      )}
                      {!file && (
                        <>
                          <p className="text-[#ABAFB3] font-thicboi text-base font-normal">Drag an image Here</p>
                          <p className="text-[#ABAFB3] font-thicboi text-base font-normal">Or</p>
                          <button
                            className="bg-[#3C60A5] text-white font-thicboi text-xs font-normal px-6 py-2 rounded-sm"
                            onClick={() => document.getElementById('file-input').click()}
                          >
                            Choose a file
                          </button>
                          <input
                            id="file-input"
                            type="file"
                            accept="image/jpeg, image/png"
                            onChange={handleFileSelect}
                            className="hidden"
                          />
                        </>
                      )}
                    </div>
                    <span className="font-thicboi text-xs text-[#84818A]">Logo Size: 500*500, Formats: png, jpg</span>
                  </div>

                  <div className='flex flex-col gap-2 border-b-[1px] border-gray-500 '>
        {/* <span className='font-thicboi text-base text-[#84818A] '>Industry<span className='font-thicboi text-base text-red-600 '>*</span></span>
        
    <input 

className='font-thicboi font-normal bg-transparent  w-full text-base  text-black placeholder-gray-400 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={industry} 
onChange={(e)=>{setIndustry(e.target.value)}}
/> */}
    </div>
    </div>
   

    <div className="flex flex-col gap-2 w-[30%]">
      <button className='bg-[#2E90FA] text-white font-thicboi text-sm font-normal rounded-lg p-3 '  onClick={()=>{handleStepClick(1)}}>Next</button>
    </div>

  
    </div>
    </>)}
    {currentStep == 1&& (<>
    <div className='flex flex-col gap-2' >

   <span className='font-medium text-3xl'>Contact Details </span> 
   <p className='font-thicboi font-normal text-base text-[#92929D]'>To set up your account, we just need a few more details</p>

    </div>
    <div className="flex flex-col gap-14">
        <div className='px-2 flex flex-col gap-8'> 
            <div className='flex flex-col gap-2 border-b-[1px] border-gray-500 '>
        <span className='font-thicboi text-base text-[#84818A] '>Website</span>
        
    <input 

className='font-thicboi font-normal bg-transparent  w-full text-base  text-black placeholder-gray-400 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={website} 
onChange={(e)=>{setWebsite(e.target.value)}}
/>
    </div>

<div className='flex gap-4'>
<div className='flex flex-col gap-2 border-b-[1px] border-gray-500 w-full'>
        <span className='font-thicboi text-base text-[#84818A] '>Company Email ID</span>
        
    <input 

className='font-thicboi font-normal bg-transparent  w-full text-base  text-black placeholder-gray-400 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={companyEmail} 
onChange={(e)=>{setCompanyEmail(e.target.value)}}
/>
    </div>
    <div className='flex flex-col gap-2 border-b-[1px] border-gray-500 w-full'>
        <span className='font-thicboi text-base text-[#84818A] '>Contact Number</span>
        
    <input 

className='font-thicboi font-normal bg-transparent  w-full text-base  text-black placeholder-gray-400 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={companyPhone} 
onChange={(e)=>{setCompanyPhone(e.target.value)}}
/>
    </div>
</div>
   

    

                  <div className='flex flex-col gap-2 border-b-[1px] border-gray-500 '>
        <span className='font-thicboi text-base text-[#84818A] '>Address<span className='font-thicboi text-base text-red-600 '>*</span></span>
        
    <input 
placeholder='Address Line 1'
className='font-thicboi font-normal bg-transparent  w-full text-base  text-black placeholder-gray-400 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={companyAdd1} 
onChange={(e)=>{setCompanyAdd1(e.target.value)}}
/>

    </div>
    <div className='flex flex-col gap-2 border-b-[1px] border-gray-500 '>
       
        
    <input 
placeholder='Address Line 2'
className='font-thicboi font-normal bg-transparent  w-full text-base  text-black placeholder-gray-400 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={companyAdd2} 
onChange={(e)=>{setCompanyAdd2(e.target.value)}}
/>

    </div>

    <div className='flex gap-4'>
<div className='flex flex-col gap-2 border-b-[1px] border-gray-500 w-full'>
      
        
    <input 
placeholder='Pincode'
className='font-thicboi font-normal bg-transparent  w-full text-base  text-black placeholder-gray-400 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={companyPincode} 
onChange={(e)=>{setCompanyPincode(e.target.value)}}
/>
    </div>
    <div className='flex flex-col gap-2 border-b-[1px] border-gray-500 w-full'>
       
        
    <input 
placeholder='City'
className='font-thicboi font-normal bg-transparent  w-full text-base  text-black placeholder-gray-400 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={companyCity} 
onChange={(e)=>{setCompanyCity(e.target.value)}}
/>
    </div>
</div>
<div className='flex gap-4'>
<div className='flex flex-col gap-2 border-b-[1px] border-gray-500 w-full'>
        
        
    <input 
placeholder='State'
className='font-thicboi font-normal bg-transparent  w-full text-base  text-black placeholder-gray-400 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={companyState} 
onChange={(e)=>{setCompanyState(e.target.value)}}
/>
    </div>
    <div className='flex flex-col gap-2 border-b-[1px] border-gray-500 w-full'>
       
        
    <input 
placeholder='Country'
className='font-thicboi font-normal bg-transparent  w-full text-base  text-black placeholder-gray-400 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={companyCountry} 
onChange={(e)=>{setCompanyCountry(e.target.value)}}
/>
    </div>
</div>
    </div>
   

    <div className="flex  gap-2 ">
      <button className='bg-[#2E90FA] text-white font-thicboi text-sm font-normal rounded-lg p-3 w-[30%]'  onClick={()=>{handleStepClick(2)}}>Next</button>
      <button className=' text-[#92929D] font-thicboi text-base font-normal  p-3 underline'  onClick={()=>{handleStepClick(2)}}>Skip</button>
    </div>

  
    </div>
    </>)}
    {currentStep == 2&& (<>
    <div className='flex flex-col gap-2' >

   <span className='font-medium text-3xl'>Details about the Company </span> 
   <p className='font-thicboi font-normal text-base text-[#92929D]'>To set up your account, we just need a few more details</p>

    </div>
    <div className="flex flex-col gap-14">
        <div className='px-2 flex flex-col gap-8'> 
            <div className='flex flex-col gap-2 border-b-[1px] border-gray-500 '>
        <span className='font-thicboi text-base text-[#84818A] '>Location</span>
        <CreatableSelect
                        value={location}
                        onChange={(selectedOption) => {
                          setLocation(selectedOption);
                        }}
                        
                        options={majorCities}
                        isClearable
                        isMulti
                        isSearchable
                        placeholder="Select or create a city..."
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            border: "none",
                            boxShadow: "none",
                          }),
                        }}
                      />  
        
    </div>

    <div className='flex flex-col gap-2 border-b-[1px] border-gray-500 '>
        <span className='font-thicboi text-base text-[#84818A] '>Departments</span>
        <CreatableSelect
                        value={departments}
                        onChange={(selectedOption) => {
                          setDepartments(selectedOption);
                        }}
                        
                        options={departmentsOptions}
                        isClearable
                        isMulti
                        isSearchable
                        placeholder="Select or create a city..."
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            border: "none",
                            boxShadow: "none",
                          }),
                        }}
                      />  
        
    </div>
    <div className='flex flex-col gap-2'>
    {/* <div className='flex flex-col gap-2 border-b-[1px] border-gray-500 '>
        <span className='font-thicboi text-base text-[#84818A] '>Languages</span>
        <CreatableSelect
                        value={language}
                        onChange={(selectedOption) => {
                          setLanguage(selectedOption);
                        }}
                        
                        options={languageOptions}
                        isClearable
                        isMulti
                        isSearchable
                        placeholder="Select or create a city..."
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            border: "none",
                            boxShadow: "none",
                          }),
                        }}
                      />  

                     
        
    </div> */}
    <p className='font-thicboi text-sm text-[#92929D]'>Note: Interview responses will be received in English by default. If another language has been added, responses can also be received in that language.</p>
    </div>

    <div className='flex flex-col gap-2 '>
        <span className='font-thicboi text-base text-[#84818A] '>Number of Employees</span>
        <div className='flex gap-2'>
        <button className='border-2 border-[#3C60A557] text-[#3C60A557] focus:text-[#3C60A5] font-thicboi text-sm font-normal rounded-lg p-3 w-[30%] focus:border-[#3C60A5]'  onClick={()=>{handleEmployees('1-10')}}>1 - 10</button>
        <button className='border-2 border-[#3C60A557] text-[#3C60A557] focus:text-[#3C60A5] font-thicboi text-sm font-normal rounded-lg p-3 w-[30%] focus:border-[#3C60A5]'  onClick={()=>{handleEmployees('10-20')}}>10 - 20</button>
        <button className='border-2 border-[#3C60A557] text-[#3C60A557] focus:text-[#3C60A5] font-thicboi text-sm font-normal rounded-lg p-3 w-[30%] focus:border-[#3C60A5]'  onClick={()=>{handleEmployees('20-30')}}>20 - 30</button>
        </div>
       
        
    </div>
   
    </div>
   

    <div className="flex  gap-2 ">
      <button className='bg-[#2E90FA] text-white font-thicboi text-sm font-normal rounded-lg p-3 w-[30%]'  onClick={()=>{handleStepClick(3)}}>Next</button>
      <button className=' text-[#92929D] font-thicboi text-base font-normal  p-3 underline'  onClick={()=>{handleStepClick(3)}}>Skip</button>
    </div>

  
    </div>
    </>)}
    {currentStep == 3&& (<>
    <div className='flex flex-col gap-2' >

   <span className='font-medium text-3xl'>Last One! </span> 
   <p className='font-thicboi font-normal text-base text-[#92929D]'>To set up your account, we just need a few more details</p>

    </div>
    <div className="flex flex-col gap-40">
        <div className='px-2 flex flex-col gap-8'> 
            <div className='flex flex-col gap-2 border-b-[1px] border-gray-500 '>
        <span className='font-thicboi text-base text-[#84818A] '>Tell us something about your company</span>
        <input 

className='font-thicboi font-normal bg-transparent  w-full text-base  text-black placeholder-gray-400 placeholder-opacity-75 placeholder:text-sm focus:outline-none focus:border-none'
value={extra} 
onChange={(e)=>{setExtra(e.target.value)}}
/>

        
    </div>

  
   
    </div>
   

    <div className="flex gap-2 ">
      <button className='bg-[#2E90FA] text-white font-thicboi text-sm font-normal rounded-lg p-3 w-[30%]'  onClick={()=>{handleDone()}}>Done</button>
    </div>

  
    </div>
    </>)}
    
    </div>)}

  </div>
  </div>
</div>

        

       <Footer/>
        
    </div>
  )
}

export default Company