import React from 'react'
import FAQ from '../components/Faq'
import Footer from '../components/footer'
import PricingMonthlyTable from '../components/PricingMonthlyTable'
import Header from '../components/Header'
import Pricingtabs from '../components/Pricingtabs'

const Pricing = () => {
  return (
    <div className='h-full '>
        <div
        className=" bg-[#0d121e] h-full bg-cover font-thicboi px-6 md:px-20 py-10"
        >
          <Header/>
            <div className="flex flex-col  items-center  text-center gap-16 px-6 md:p-20">
               
                <div className="w-full flex items-center justify-center ">
                    <p className="text-white text-center font-bold font-thicboi text-3xl md:text-5xl">Simple, transparent pricing</p>
                </div>
                <Pricingtabs></Pricingtabs>
                {/* <div className=" border border-[#FFFFFF33] bg-[#FFFFFF1A] rounded-full items-center space-x-3 flex p-2">
                    <div className="bg-white py-3 px-6 rounded-full">
                        <p className="">Monthly Billed</p>
                    </div>
                    <div className="py-3 px-6">
                        <p className="text-white ">Annually Billed</p>
                    </div>
                </div> */}
                {/* <div className=''>
                <PricingMonthlyTable/>
                </div> */}
                

            </div>
            
        </div>
        <FAQ fade={false}></FAQ>

       <Footer/>
        
    </div>
  )
}

export default Pricing