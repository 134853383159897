import {HashRouter as Router,Routes,Route,} from "react-router-dom";
import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus";
import TermsAndCondition from "./pages/TermsCondition";
import Privacy from "./pages/Privacy";
import Demo from "./pages/Demo";
import Price from "./pages/Price";
import Company from "./pages/Company"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}></Route>
        <Route path="/about" element={<Aboutus/>}></Route>
        <Route path="/terms" element={<TermsAndCondition/>}></Route>
        <Route path="/privacy" element={<Privacy/>}></Route>
        <Route path="/demo" element={<Demo/>}></Route>
        <Route path="/pricing" element={<Price/>}></Route>
        <Route path="/checkout" element={<Company/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
